<template>
  <div>
    <v-simple-table fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              style="min-width: 250px"
              class="text-left"
              v-for="header in itemHeaders"
              v-bind:key="header.text"
            >{{header.text}}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in saleItemsData" :key="index">
            <td>
              <v-btn icon @click="openItemModal(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              {{ item.ItemCode }}
            </td>
            <td>{{ item.Dscription }}</td>
            <td>{{ item.CodeBars }}</td>
            <td>{{ item.SerialNum }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.DelivrdQty }}</td>
            <td>{{ item.UseBaseUn }}</td>
            <td>{{ item.PackQty }}</td>
            <td>{{ item.PriceBefDi }}</td>
            <td>{{ item.DiscPrcnt }}</td>
            <td>{{ item.Rate }}</td>
            <td>{{ item.Price }}</td>
            <td>{{ item.TaxCode }}</td>
            <td>{{ item.PriceAfVAT }}</td>
            <td>{{ item.LineTotal }}</td>
            <td>{{ item.WhsCode }}</td>
            <td>{{ item.ShipDate }}</td>
            <td>{{ item.SlpCode }}</td>
            <td>{{ item.Commission }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.OnHand }}</td>
            <td>{{ item.IsCommited }}</td>
            <td>{{ item.OnOrder }}</td>
            <td>{{ item.TreeType }}</td>
            <td>{{ item.LineStatus }}</td>
            <td>{{ item.TargetType }}</td>
            <td>{{ item.TargetEntry }}</td>
            <td>{{ item.BaseType }}</td>
            <td>{{ item.BaseRef }}</td>
            <td>{{ item.BaseEntry }}</td>
            <td>{{ item.BaseLine }}</td>
            <td>{{ item.SpecPrice }}</td>
            <td>{{ item.VatSum }}</td>
            <td>{{ item.GrssProfit }}</td>
            <td>{{ item.PoTrgNum }}</td>
            <td>{{ item.BackOrdr }}</td>
            <td>{{ item.FreeTxt }}</td>
            <td>{{ item.PickStatus }}</td>
            <td>{{ item.UomCode }}</td>
            <td>{{ item.unitMsr }}</td>
            <td>{{ item.NumPerMsr }}</td>
            <td>{{ item.Text }}</td>
            <td>{{ item.GTotal }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.Quantity }}</td>
            <td>{{ item.UgpCode }}</td>
            <td>{{ item.AgrNo }}</td>
            <td>{{ item.LinePoPrss }}</td>
            <td>{{ item.OnHand }}</td>
            <td>{{ item.IsCommited }}</td>
            <td>{{ item.OnOrder }}</td>
            <td>
              <v-btn icon color="red" @click="deleteItemModal({ index: index, name: item.Dscription })">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <!-- end of data table -->

    <!-- start of item edit modal -->

    <!-- end of item modal -->

    <!-- start of delete dialog -->
    <v-dialog v-model="deleteDialog" width="800px">
      <v-toolbar color="primary" dense dark>
        <v-toolbar-title>Delete Item</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="deleteDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row class="pa-3">
            <h2>Are you sure you want delete - {{itemName}} ?</h2>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn color="red" dark @click="deleteItem" :right="trueValue">Yes</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of delete dialog -->

    <!-- start of edit item dialog -->
        <!-- start of item dialog -->
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="accent">
          <v-toolbar-title>
            <v-btn icon dark @click="dialog = false">
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>A/R Credit Memo Item Entry
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
           <item-editor :initial="record" @itemData="addItem"></item-editor>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end of item dialog -->


  </div>
</template>

<script>
import { itemHeaders } from "../_data/index.js";
import ItemEditor from "./item-editor.vue";
export default {
  components: {
    ItemEditor
  },
  props: {
    saleItemsData: {
      type: Array
    }
  },
  data() {
    return {
      itemHeaders: itemHeaders,
      dialog: false,
      trueValue: true,
      deleteDialog: false,
      editDialog: false,
      itemIndex: null,
      itemName: null,
      record: {}
    };
  },
  methods: {
    deleteItemModal(data) {
      this.itemIndex = data.index;
      this.itemName = data.name;
      this.deleteDialog = true;
    },
    deleteItem() {
      const index = this.itemIndex;
      this.saleItemsData.splice(index, 1);
      this.deleteDialog = false;
    },
    openItemModal(item) {
      this.record = item;
      this.dialog = true;
    },
    addItem() {

    }
  }
};
</script>

<style scoped>
</style>